import {formQuestions} from './Form';
import {
    PRODUCT_TYPE_VEHICLE,
    PRODUCT_TYPE_FUNERAL,
    PRODUCT_TYPE_LIFE,
    productTypeLabels
} from '../../shared/Constants'

class UpdatePolicyPresenter {
    constructor(
        policyData,
        updateState, 
        userService,
        policyService 
    ) {
        let {token, policyId, policyNumber} = policyData;

        let productType = parseInt(policyData.productType);

        let productTypeLabel = productTypeLabels[productType];

        let user = {
            email: "",
            phone: "",
        }

        let selectedForm = this.getFormByProductType(productType);
        let aggregatedError = "";

        this.state = {
            token,
            user,
            policy: policyNumber,
            policyId: policyId,
            selectedForm,
            isLoading: false,
            showDialog: false,
            aggregatedError,
            productTypeLabel
        };

        this.updateState = updateState;
        this.userService = userService;
        this.policyService = policyService;
    }

    getUserData = async () => {
        this.startLoading();
        var response = await this.userService.getUserData(this.state.token);
        if (response !== null) {
            let user = {
                email: response.email,
                phone: response.phones[0]
            }
            this.state.user = user
            this.updateState(this.state);
        }
        this.stopLoading();
    }

    save = async (event) => {
        this.clearErrors();
        window.scrollTo(0, 0);
        if (!this.validateForm()) {
            return new Promise((resolve, _) => {
                resolve(false);
            });
        }

        this.startLoading();
        let request = this.makeRequest();
        let response = await this.policyService.updatePolicy(this.state.token, request);
        this.showDialog();
        this.stopLoading();
        return response;
    }

    validateForm = () => {
        var isValid = true;

        var questionsFilled = 0;

        this.state.selectedForm.forEach( question => {
            if (question.checked) {
                questionsFilled += 1;
            }

            if (question.checked && !question.value) {
                question.error = "Campo requerido."
                this.updateState({
                    selectedForm: this.state.selectedForm
                });
                isValid = false;
            }   
        });

        if (questionsFilled === 0) {
            this.state.aggregatedError = "Seleccione algún campo a corregir."
            this.updateState({
                errors: this.state.errors
            });
            isValid = false;
        }

        return isValid;
    }

    makeRequest = () => {
        let request = {
            Email: this.state.user.email,
            esBenPref: 0, // TODO: change for value from response
            PolizaID: this.state.policyId,
            CorrectionsDescriptionList: []
        };

        this.state.selectedForm.forEach( question => {
            if (question.checked) {
                request.CorrectionsDescriptionList.push( {
                    Solicitud: question.label,
                    Descripcion: question.value
                } );
            }
        });
        return request;
    }

    onQuestionChecked = (index) => {
        this.state.selectedForm[index].checked = !this.state.selectedForm[index].checked;
        this.updateState({ selectedForm: this.state.selectedForm });
    }

    onQuestionChange = (event, index) => {
        const newValue = event.target.value;
        this.state.selectedForm[index].value = newValue;
        this.updateState({ form: this.state.form });
    }

    startLoading = () => {
        this.updateState(
            {isLoading: true}
        );
    }

    stopLoading = () => {
        this.updateState(
            {isLoading: false}
        );
    }

    showDialog = () => {
        this.state.showDialog = true;
        this.updateState( this.state );
    }

    hideDialog = () => {
        this.state.showDialog = false;
        this.updateState( this.state );
    }

    onBackClicked = () => {
        if( window.android ) {
            window.android.back();
        } else if( window.webkit ) {
            window.webkit.messageHandlers.updatePolicy.postMessage( "back" );
        } else {
            this.hideDialog();
            console.log( "Back button pressed" );
        }
    }

    clearErrors = () => {
        this.state.aggregatedError = "";
        this.state.selectedForm.forEach( question => {
            question.error = "";
        } );
        this.updateState(this.state);
    }

    onCloseSnackbar = () => {
        this.clearErrors();
    }

    onDialogClose = () => {
        this.onBackClicked();
    }

    getFormByProductType = productType => {
        switch (productType) {
            case PRODUCT_TYPE_VEHICLE:
                return formQuestions.vehicle;
            case PRODUCT_TYPE_FUNERAL:
            case PRODUCT_TYPE_LIFE:
                return formQuestions.life_funeral;
            default:
                return [];
        }
    }
}

export default UpdatePolicyPresenter;