import BenefitsRepository from '../models/BenefitsRepository';
import RefFriendRequestDTO from './RefFriendRequestDTO';
import {ODESSA_URL} from '../../shared/Constants';
import ReferenceDTO from './ReferenceDTO';

export default class BenefitsRepositoryImpl implements BenefitsRepository {

    REF_FRIEND_ENDPOINT = "Share/Benefits/Ref";
    REF_BUYER_ENDPOINT = "Share/Benefits/Ref/VentaUnidad";
    REFERENCES_LIST_ENDPOINT = "Share/References/List";

    refFriend = async (token: string, request: RefFriendRequestDTO): Promise<boolean> => {

        const headers = new Headers({
            token,
            "Content-Type": "application/json;charset=utf-8"
        })

        const options = { 
            method: 'POST',
            headers: headers,
            body: JSON.stringify(request)
        };

        await fetch( `${ODESSA_URL}/${this.REF_FRIEND_ENDPOINT}`, options );
        return true;
    }

    refBuyer = async (token: string, request: RefFriendRequestDTO): Promise<boolean> => {

        const headers = new Headers({
            token,
            "Content-Type": "application/json;charset=utf-8"
        })

        const options = { 
            method: 'POST',
            headers: headers,
            body: JSON.stringify(request)
        };

        await fetch( `${ODESSA_URL}/${this.REF_BUYER_ENDPOINT}`, options );
        return true;
    }

    listReferences = async (token: string): Promise<ReferenceDTO[]> => {
        const headers = new Headers({
            token,
            "Content-Type": "application/json;charset=utf-8"
        })

        const options = { 
            method: 'GET',
            headers: headers
        };

        let response = (await fetch(`${ODESSA_URL}/${this.REFERENCES_LIST_ENDPOINT}`, options)).json();
        return response;
    }
}