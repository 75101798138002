import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import {Typography} from '@material-ui/core'
import {Radio} from '@material-ui/core';
import {RadioGroup} from '@material-ui/core';
import {FormControlLabel} from '@material-ui/core';
import AddressWithCP from './AddressWithCP'
import AddressWithoutCP from './AddressWithoutCP';
import {TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import {FormHelperText} from '@material-ui/core';
import {ODESSA_URL} from '../../shared/Constants';

const countriesEndPoint = "Person/ObtenPaises";
const statesEndPoint = "Person/ObtenEstados";

class AddressForm extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            paises: null,
            conoceCP: "true",
            pais: "",
            calle: "",
            noExt: "",
            noInt: "",
            calles: "",
            cp: "",
            estado: "",
            municipio: "",
            colonia: "",
            estados: []
        }
    }

    componentDidMount() {
        this.getCountries();
    }

    getCountries( ) {

        var getCountriesHeaders = new Headers({
            "token": this.props.token
        });

        var getCountriesOptions = { 
            method: 'GET',
            headers: getCountriesHeaders,
            mode: 'cors',
            cache: 'default' 
        };

        fetch( `${ODESSA_URL}/${countriesEndPoint}`, getCountriesOptions )
        .then( response => {
            return response.json()
         } ).then( data => {
            var paises = data.map( (country) => {
                return { value: country.id, label: country.name };
            } );
            this.setState( {
                paises,
                paisSeleccionado: paises[0]
            } );
         } );
    }

    onConoceCPChange = ( event ) => {

        this.setState( {
            conoceCP: event.target.value
        } )
    }


    onPaisSeleccionado = ( paisSeleccionado ) => {
        const newState = { pais: paisSeleccionado };
        this.setState( newState );
        this.props.onDataChange( newState );

        this.getStates();
    }


    getStates( ) {

        var getStatesHeaders = new Headers({
            "token": this.props.token
        });

        var getStatesURL = `${ODESSA_URL}/${statesEndPoint}/${this.state.paisSeleccionado.value}`;

        var getStatesOptions = { 
            method: 'GET',
            headers: getStatesHeaders,
            mode: 'cors',
            cache: 'default' 
        };

        fetch( getStatesURL, getStatesOptions )
        .then( response => {
            return response.json()
         } ).then( data => {
            var states = data.map( (state) => {
                return { value: state.id, label: state.name };
            } );
            this.setState( {
                estados: states,
            } );
         } );
    }


    onCalleChange = ( event )  => {
        const newState = { calle: event.target.value };
        this.setState( newState );
        this.props.onDataChange( newState );
    } 


    onNoIntChange = ( event )  => {
        const newState = { noInt: event.target.value };
        this.setState( newState );
        this.props.onDataChange( newState );
    } 


    onNoExtChange = ( event )  => {
        const newState = { noExt: event.target.value };
        this.setState( newState );
        this.props.onDataChange( newState );
    } 


    onCallesChange = ( event )  => {
        const newState = { calles: event.target.value };
        this.setState( newState );
        this.props.onDataChange( newState );
    } 


    onDataChange = ( data ) => {
        this.setState( data );
        this.props.onDataChange( data );
    }

    render() {
        return(
            <div className="new-section" >

                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography  gutterBottom >
                            País
                        </Typography>
                        <Select
                            value={this.state.pais}
                            options={this.state.paises}
                            onChange={this.onPaisSeleccionado} />
                        { this.props.formErrors.pais !== "" ? <FormHelperText error>{this.props.formErrors.pais}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>


                        <Typography  gutterBottom >
                            ¿Conoce el código postal?
                        </Typography>
                        
                        <RadioGroup aria-label="¿Conoce el código postal?"
                            name="known-cp"
                            onChange={this.onConoceCPChange}
                            value={this.state.conoceCP} >

                            <FormControlLabel
                                value="true"
                                control={ <Radio color="primary" /> }
                                label="Sí" />
    
                            <FormControlLabel
                                value="false"
                                control={ <Radio color="primary" /> }
                                label="No" />

                        </RadioGroup>


                    </Grid>
                </Grid>

                { this.state.conoceCP === "true" ?
                    <AddressWithCP
                        onDataChange={this.onDataChange}
                        formErrors={this.props.formErrors}
                        token={this.props.token} />
                :
                    <AddressWithoutCP 
                        onDataChange={this.onDataChange}
                        formErrors={this.props.formErrors} 
                        estados={this.state.estados}
                        token={this.props.token} />
                }

                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <TextField 
                            required
                            fullWidth
                            name="calle"
                            label="Calle"
                            variant="outlined"
                            onChange={this.onCalleChange} />
                        { this.props.formErrors.calle !== "" ? <FormHelperText error>{this.props.formErrors.calle}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={6}>
                        <TextField 
                            required
                            fullWidth
                            name="noExt"
                            label="No. Exterior"
                            variant="outlined"
                            onChange={this.onNoExtChange} />
                        { this.props.formErrors.noExt !== "" ? <FormHelperText error>{this.props.formErrors.noExt}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={6}>
                        <TextField 
                            fullWidth
                            name="noInt"
                            label="No. Interior"
                            variant="outlined"
                            onChange={this.onNoIntChange} />
                    </Grid>


                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            name="calles"
                            label="Entre calles"
                            variant="outlined"
                            onChange={this.onCallesChange} />
                    </Grid>
                    

                </Grid>
                
            </div>
        );
    }
}


AddressForm.propTypes = {
    onDataChange: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired
}

export default AddressForm;