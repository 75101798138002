import RefBuyerFormPresenter from './RefBuyerFormPresenter';
import BenefitsRepositoryImpl from '../../core/data/BenefitsRepositoryImpl';
import BenefitsServiceImpl from '../../core/usecases/BenefitsService';

export default class RefBuyerFormPresenterFactory {
    static make(token, updateState, onSave) {
        let benefitsRepository = new BenefitsRepositoryImpl();
        let benefitsService = new BenefitsServiceImpl(benefitsRepository);
        let presenter = new RefBuyerFormPresenter(token, benefitsService, updateState, onSave);
        return presenter;
    }
}