import React, { Component } from 'react';
import {PropTypes} from 'prop-types';
import Header from '../shared/Header';
import RefBuyerFormComponent from './RefBuyerFormComponent';
import '../shared/styles.css';
import RefFriendSavedComponent from '../refFriend/RefFriendSavedComponent';

class RefBuyerComponent extends Component {

    constructor( props ) {
        super( props );
        const token = props.match.params.token;
        this.state = {
            saved: false,
            name: "",
            token
        };
    }

    onSave = ( name ) => {
        this.setState( {
            saved: true,
            name
        } )
    }

    render() {
        return (
            <React.Fragment>                
                <Header />
                { !this.state.saved ?
                    <RefBuyerFormComponent token={this.state.token} onSave={this.onSave} />
                :
                    <RefFriendSavedComponent name={this.state.name} />
                }  
            </React.Fragment>
        );
    }
}

RefBuyerComponent.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired
        })
    })
}

export default RefBuyerComponent;