import Util from '../../shared/Util';

export default class RefBuyerFormPresenter {
    constructor(
        token, 
        benefitsService,
        updateState, 
        onSave
    ) {
        this.state = {
            name: "",
            lastName: "",
            secondLastName: "",
            email: "",
            phone: "",
            formErrors: this.getEmptyFormErrors(),
            isLoading: false,
            token
        };

        this.updateState = updateState;
        this.benefitsService = benefitsService;
        this.onSave = onSave;
    }

    getEmptyFormErrors = () => {
        var emptyFormErrors = {
            name: "",
            lastName: "",
            secondLastName: "",
            phone: "",
            email: ""
        };

        return emptyFormErrors;
    }

    onNameChange = ( event ) => {
        this.state.name = event.target.value;
        this.updateState( this.state );
    }

    onLastNameChange = ( event ) => {
        this.state.lastName = event.target.value;
        this.updateState(this.state);
    }

    onSecondLastNameChange = ( event ) => {
        this.state.secondLastName = event.target.value;
        this.updateState(this.state);
    }

    onEmailChange = ( event ) => {
        this.state.email = event.target.value;
        this.updateState(this.state);
    }

    onPhoneChange = ( event ) => {
        this.state.phone = event.target.value;
        this.updateState(this.state);
    }

    onBackClicked = () => {

        if( window.android ) {
            window.android.back();
        } else if( window.webkit ) {
            window.webkit.messageHandlers.refFriend.postMessage( "back" );
        } else {
            console.log( "Back button pressed" );
        }
    }

    save = async ( event ) => {

        if ( !this.validateForm() ) {
            return false;
        }

        let request = this.makeRequest();
        this.startLoading();
        
        let response = await this.benefitsService.refBuyer(this.state.token, request);
        
        if (response) {
            this.onSave(`${this.state.name} ${this.state.lastName} ${this.state.secondLastName}`);
            this.stopLoading();
        }
        return response;
    }

    makeRequest = () => {
        let request = {
            NombreInvitado: this.state.name,
            ApellidoPaternoInvitado: this.state.lastName,
            ApellidoMaternoInvitado: this.state.secondLastName,
            TelefonoInvitado: this.state.phone,
            CorreoInvitado: this.state.email
        };
        return request;
    }

    startLoading = () => {
        this.state.isLoading = true;
        this.updateState(this.state);
    }

    stopLoading = () => {
        this.state.isLoading = false;
        this.updateState(this.state);
    }

    validateForm = () => {
        var isValidForm = true;
        this.state.formErrors = this.getEmptyFormErrors();

        if ( this.state.name === "" ) {
            this.state.formErrors.name = "Proporcione el nombre del referenciado";
            this.updateState (this.state);
            isValidForm = false;
        }

        if ( this.state.lastName === "" ) {
            this.state.formErrors.lastName = "Proporcione el apellido paterno";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.secondLastName === "" ) {
            this.state.formErrors.secondLastName = "Proporcione el apellido materno";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( (this.state.email === "" || !Util.isEmail(this.state.email)) && this.state.phone === "" ) {
            this.state.formErrors.email = "Proporcione el teléfono y/o correo válidos del referenciado";
            this.state.formErrors.phone = "Proporcione el teléfono y/o correo válidos del referenciado";
            this.updateState(this.state);
            isValidForm = false;
        }

        return isValidForm;
    }
}