import AddBeneficiaryPresent from './AddBeneficiaryPresenter';
import UserRepositoryImpl from '../../core/data/UserRepositoryImpl';
import UserServiceImpl from '../../core/usecases/UserService';
import PolicyRepositoryImpl from '../../core/data/PolicyRepositoryImpl';
import PolicyServiceImpl from '../../core/usecases/PolicyService';
import BeneficiaryRepositoryImpl from '../../core/data/BeneficiaryRepositoryImpl';
import BeneficiaryServiceImpl from "../../core/usecases/BeneficiaryService";

export default class AddBeneficiaryPresentFactory {
    static make = (token,
        policyNumber,
        policyId,
        updateState
    ) => {
        let userRepository = new UserRepositoryImpl();
        let userService = new UserServiceImpl(userRepository);
        let policyRepository = new PolicyRepositoryImpl();
        let policyService = new PolicyServiceImpl(policyRepository);
        let beneficiaryRepository = new BeneficiaryRepositoryImpl();
        let beneficiaryService = new BeneficiaryServiceImpl(beneficiaryRepository);

        let presenter = new AddBeneficiaryPresent(token, policyNumber, policyId, beneficiaryService, userService, policyService, updateState);
        return presenter;
    }
}