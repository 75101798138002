class User {
    email: string
    phones: Array<string>

    constructor(email: string, phones: Array<string>) {
        this.email = email;
        this.phones = phones;
    }
}

export default User;