import React, { Component } from 'react';
import Select from 'react-select';
import {Grid} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {FormHelperText} from '@material-ui/core';
import PropTypes from 'prop-types';
import {ODESSA_URL} from '../../shared/Constants';

const municipiosEndPoint = "Person/ObtenPoblaciones";
const coloniasEndPoint = "Person/ObtenColonias";

class AddressWithoutCP extends Component {

    constructor( props ) {
        super( props );

        this.state = {
            estado: "",
            estadoSeleccionado: null,
            municipios: [],
            municipio: "",
            municipioSeleccionado: null,
            colonias: [],
            colonia: "",
            coloniaSeleccionada: null,
            cp: ""

        }
    }

    onEstadoSelected = ( selectedItem ) => {

        this.setState( {
            estadoSeleccionado: selectedItem,
            estado: selectedItem.value,
            estadoLabel: selectedItem.label
        } );


        this.props.onDataChange( { estado: selectedItem.value } );


        const obtieneMunicipiosURL = `${ODESSA_URL}/${municipiosEndPoint}/${selectedItem.value}`;

        const municipiosHeaders = new Headers({
            "token": this.props.token
        });

        var municipiosOptions = { 
            method: 'GET',
            headers: municipiosHeaders,
            mode: 'cors',
            cache: 'default' 
        };

        fetch( obtieneMunicipiosURL, municipiosOptions )
        .then( response => {
            return response.json();
        } )
        .then ( municipiosList => {

            var municipios = municipiosList.map( municipio => ({
                value: municipio.id,
                label: municipio.name
            }) );

            this.setState({
                municipios,
                municipio: "",
                municipioLabel: "",
                municipioSeleccionado: null,
                colonia: "",
                coloniaLabel: "",
                coloniaSeleccionada: null
            });

            this.props.onDataChange( { municipio: "", colonia: "", cp: "" } );

        } );

    }


    onMunicipioSelected = ( selectedItem ) => {

        this.setState( {
            municipioSeleccionado: selectedItem,
            municipio: selectedItem.value,
            municipioLabel: selectedItem.label
        } );

        this.props.onDataChange( { municipio: selectedItem.value } );

        const obtieneColoniasURL = `${ODESSA_URL}/${coloniasEndPoint}/${this.state.estado}/${selectedItem.value}/-1/1`;

        const coloniasHeaders = new Headers({
            "token": this.props.token
        });

        var coloniasOptions = { 
            method: 'GET',
            headers: coloniasHeaders,
            mode: 'cors',
            cache: 'default' 
        };

        fetch( obtieneColoniasURL, coloniasOptions )
        .then( response => {
            return response.json();
        } )
        .then ( coloniasList => {

            var colonias = coloniasList.map( colonia => ({
                value: colonia.id,
                label: colonia.name,
                cp: colonia.cp
            }) );

            this.setState({
                colonias,
                colonia: "",
                coloniaLabel: "",
                coloniaSeleccionada: null
            });

            this.props.onDataChange( { colonia: "", cp: "" } );
        } );

    }


    onColoniaSelected = ( selectedItem ) => {

        this.setState( {
            coloniaSeleccionada: selectedItem,
            colonia: selectedItem.value,
            coloniaLabel: selectedItem.label,
            cp: selectedItem.cp
        } );


        this.props.onDataChange( { colonia: selectedItem.value, cp: selectedItem.cp } );

        
    }


    render() {
        return (
            <div className="new-section" >
                <Grid container spacing={3} > 
                    <Grid item xs={12}>
                        <Typography  gutterBottom >
                            Estado
                        </Typography>
                        <Select 
                            name="estado"
                            value={this.state.estadoSeleccionado}
                            options={this.props.estados}
                            onChange={this.onEstadoSelected} />
                        { this.props.formErrors.estado !== "" ? <FormHelperText error>{this.props.formErrors.estado}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>
                        <Typography  gutterBottom >
                            Municipio
                        </Typography>
                        <Select 
                            name="municipio"
                            value={this.state.municipioSeleccionado}
                            options={this.state.municipios}
                            onChange={this.onMunicipioSelected} />
                        { this.props.formErrors.municipio !== "" ? <FormHelperText error>{this.props.formErrors.municipio}</FormHelperText> :  "" }
                    </Grid>


                    <Grid item xs={12}>
                        <Typography  gutterBottom >
                            Colonia
                        </Typography>
                        <Select 
                            name="colonia"
                            value={this.state.coloniaSeleccionada}
                            options={this.state.colonias}
                            onChange={this.onColoniaSelected} />
                        { this.props.formErrors.colonia !== "" ? <FormHelperText error>{this.props.formErrors.colonia}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>
                        Código Postal: {this.state.cp}
                    </Grid>

                </Grid>
            </div>
        );
    }
}

AddressWithoutCP.propTypes = {
    onDataChange: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    estados: PropTypes.object.isRequired
}

export default AddressWithoutCP;