import UpdatePolicyPresenter from './UpdatePolicyPresenter';
import UserRepositoryImpl from '../../core/data/UserRepositoryImpl';
import UserServiceImpl from '../../core/usecases/UserService';
import PolicyRepositoryImpl from '../../core/data/PolicyRepositoryImpl';
import PolicyServiceImpl from '../../core/usecases/PolicyService';

export default class UpdatePolicyPresenterFactory {
    static make(policyData, updateState) {
        let userRepository = new UserRepositoryImpl();
        let userService = new UserServiceImpl(userRepository);
        let policyRepository = new PolicyRepositoryImpl();
        let policyService = new PolicyServiceImpl(policyRepository);
        let presenter = new UpdatePolicyPresenter(policyData, updateState, userService, policyService);
        return presenter;
    }
}