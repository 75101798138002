import BenefitsRepository from '../models/BenefitsRepository';
import Reference from '../models/Reference';

export interface BenefitsService {
    refFriend(token: string, request: any): Promise<boolean>;
    refBuyer(token: string, request: any ): Promise<boolean>;
    listReferences(token: string): Promise<Reference[]>;
}


export default class BenefitsServiceImpl implements BenefitsService {

    benefitsRepository: BenefitsRepository;

    constructor(benefitsRepository: BenefitsRepository) {
        this.benefitsRepository = benefitsRepository;
    }
    
    refFriend = async (token: string, request: any ): Promise<boolean> => {
        return this.benefitsRepository.refFriend(token, request);
    }

    refBuyer = async (token: string, request: any ): Promise<boolean> => {
        return this.benefitsRepository.refBuyer(token, request);
    }

    listReferences = async (token: string): Promise<Reference[]> => {
        let response = await this.benefitsRepository.listReferences(token);
        let references:Reference[] = response.map( referenceDTO => {
            return new Reference(
                referenceDTO.Nombre,
                referenceDTO.Correo,
                referenceDTO.Estatus,
                referenceDTO.PolizasVigentes,
                referenceDTO.FechaRegistro
            );
        });
        return references;
    }
}