
import MyReferencesPresenter from "./MyReferencesPresenter";
import BenefitsRepositoryImpl from "../../core/data/BenefitsRepositoryImpl";
import BenefitsServiceImpl from "../../core/usecases/BenefitsService";

export default class MyReferencesPresenterFactory {
    static make = (token, updateState) => {
        let benefitsRepository = new BenefitsRepositoryImpl();
        let benefitsService = new BenefitsServiceImpl(benefitsRepository);
        return new MyReferencesPresenter(token, benefitsService, updateState);
    }
}