import User from '../models/User';
import UserRepository from '../models/UserRepository';
import PersonaDTO from '../data/PersonaDTO';

export interface UserService {
    getUserData(token: string): Promise<User>;
}

export default class UserServiceImpl implements UserService {

    userRepository: UserRepository;

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository;
    }
    
    getUserData = async (token: string): Promise<User> => {
        let personaDTO: PersonaDTO = await this.userRepository.getUserData(token);    
        return this.responseToUserResponse(personaDTO);
    }

    private responseToUserResponse = (persona: PersonaDTO): User => {
        let phones: Array<string> = persona.m_Telefonos.split(";").map((phone: string) => {
            let phoneData = phone.split(",");
            return phoneData[2];
        }).filter((phone: string) => {
            return phone !== undefined
        });
        let userDataResponse = new User(
            persona.m_CorreoElectronico, 
            phones
        );
        return userDataResponse;
    }

}