import React, { Component } from 'react';
import {PropTypes} from 'prop-types';
import Header from '../shared/Header';
import RefFriendFormComponent from './RefFriendFormComponent';
import '../shared/styles.css';
import RefFriendSavedComponent from './RefFriendSavedComponent';

class RefFriendComponent extends Component {

    constructor( props ) {
        super( props );
        const token = props.match.params.token;
        const fromList = props.match.params.fromList;
        this.state = {
            saved: false,
            name: "",
            token,
            fromList
        };
    }

    onSave = ( name ) => {
        this.setState( {
            saved: true,
            name
        } )
    }

    render() {
        return (
            <React.Fragment>                
                <Header />
                { !this.state.saved ?
                    <RefFriendFormComponent token={this.state.token} fromList={this.state.fromList} onSave={this.onSave} />
                :
                    <RefFriendSavedComponent fromList={this.state.fromList} name={this.state.name} />
                }  
            </React.Fragment>
        );
    }
}

RefFriendComponent.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired
        })
    })
}

export default RefFriendComponent;