import PolicyRepository from '../models/PolicyRepository';
import {ODESSA_URL} from '../../shared/Constants';
import UpdatePolicyRequestDTO from './UpdatePolicyRequestDTO';

export default class PolicyRepositoryImpl implements PolicyRepository {
    UPDATE_POLICY_ENDPOINT = "Quotes/PolicyCorrection";

    updatePolicy = async (token: string, request: UpdatePolicyRequestDTO): Promise<boolean> => {
        const headers = new Headers({
            token,
            "Content-Type": "application/json;charset=utf-8"
        })

        const options = { 
            method: 'POST',
            headers,
            body: JSON.stringify(request)
        };

        await fetch(`${ODESSA_URL}/${this.UPDATE_POLICY_ENDPOINT}`, options);
        return true;
    } 
}