export default class Reference {
    name: String = "";
    email: String = "";
    status: String = "";
    policies: String = "";
    registryDate: String = "";

    constructor(name: String, email: String, status: String, policies: String, registryDate: String) {
        this.name = name;
        this.email = email;
        this.status = status;
        this.policies = policies;
        this.registryDate = registryDate;
    }
}