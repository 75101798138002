import React, {Component} from 'react';
import {Paper, Typography, Grid} from '@material-ui/core';
import {Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import RefFriendSavedPresenterFactory from './RefFriendSavedPresenterFactory';

class RefFriendSavedComponent extends Component {

    constructor( props ) {
        super( props );
        let fromList = props.fromList;
        let token = props.token;
        this.presenter = RefFriendSavedPresenterFactory.make(token, fromList);
    }

    render() {
        return (
            <Paper className="layout" id="refFriendSaved">
                <Typography variant="h5" className="odessa-text">
                    ¡Gracias por compartir!
                </Typography>
                <div className="new-section" />
                <Typography paragraph={true}>
                    Le hemos enviado a tu referenciado {this.props.name} un correo 
                    con una liga de acceso temporal en la que podrá cotizar, 
                    contratar y administrar las pólizas de sus autos.
                </Typography>
                <Typography paragraph={true}>
                    Por cada póliza contratada y pagada, 
                    obtendrás un premio consistente en <span className="odessa-text"> un vale de entrada al cine 
                    para 2 personas y un combo de palomitas y refresco , 
                    el cuál te haremos llegar, vía correo electrónico.</span>
                </Typography>
                <Typography paragraph={true}>
                    Para dar seguimineto al estatus de tus referenciados, 
                    accede a la sección <span className="boldText">"Mis Referenciados"</span> en el menú de tu portal.
                </Typography>
                <Typography paragraph={true} className="odessa-text">
                    Mientras más compartes, más ganas.
                </Typography>
                <Grid item xs={12} className="centered-content" >
                    <Button
                        variant="contained" 
                        color="primary"
                        onClick={this.presenter.onBackClicked} >
                        Cerrar
                    </Button>
                </Grid>
            </Paper>
        );
    }
}

RefFriendSavedComponent.propTypes = {
    name: PropTypes.string.isRequired
};

export default RefFriendSavedComponent;