export default class AddBeneficiaryPresenter {
    constructor(
        token,
        policyNumber,
        policyId,
        beneficiaryService,
        userService,
        policyService,
        updateState
    ) {
        this.state = {
            token,
            beneficiaryList: [],
            beneficiarySelected: null,
            isLoading: false,
            user: null,
            policyId,
            policyNumber,
            showDialog: false,
            aggregatedError: ""
        };

        this.updateState = updateState;
        this.beneficiaryService = beneficiaryService;
        this.userService = userService;
        this.policyService = policyService;
    }

    fetchBeneficiaryList = async () => {
        let beneficiaries = await this.beneficiaryService.listBeneficiaries(this.state.token);
        this.state.beneficiaryList = beneficiaries.map( beneficiary => {
            return {
                value: beneficiary.id,
                label: beneficiary.name
            };
        } );
        this.updateState(this.state);
    }

    getUserData = async () => {
        this.startLoading();
        var response = await this.userService.getUserData(this.state.token);
        if (response !== null) {
            let user = {
                email: response.email,
                phone: response.phones[0]
            }
            this.state.user = user
            this.updateState(this.state);
        }
        this.stopLoading();
    }

    onBeneficiarySelected = (beneficiarySelected) => {
        this.state.beneficiarySelected = beneficiarySelected;
        this.updateState(this.state);
    }

    isBeneficiarySelected = () => {
        if (this.state.beneficiarySelected === null) {
            this.state.aggregatedError = "Seleccione el beneficiario";
            this.updateState(this.state);
            return false;
        }
        return true;
    }


    save = async () => {
        this.clearError();

        if (!this.isBeneficiarySelected()) {
            return false;
        }

        this.startLoading();
        let request = this.makeRequest();
        await this.policyService.updatePolicy(this.state.token, request);
        this.showDialog();
        this.stopLoading();
    }

    makeRequest = () => {
        let request ={ 
            Email: this.state.user.email,
            esBenPref: 0, // TODO: change for value from response
            PolizaID: this.state.policyId,
            CorrectionsDescriptionList: [{
                Solicitud: "Agregar Beneficiario Preferente",
                Descripcion: this.state.beneficiarySelected.label
            }]
        };
        return request;
    }

    onBackClicked = () => {
        if( window.android ) {
            window.android.back();
        } else if( window.webkit ) {
            window.webkit.messageHandlers.addBeneficiary.postMessage( "back" );
        } else {
            console.log( "Back button pressed" );
        }
    }

    startLoading = () => {
        this.state.isLoading = true;
        this.updateState(this.state);
    }

    stopLoading = () => {
        this.state.isLoading = false;
        this.updateState(this.state);
    }

    showDialog = () => {
        this.state.showDialog = true;
        this.updateState(this.state);
    }

    onDialogClose = () => {
        this.onBackClicked();
        this.state.showDialog = false;
        this.updateState(this.state);
    }

    clearError = () => {
        this.state.aggregatedError = "";
        this.updateState(this.state);
    }
}