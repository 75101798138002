import React, {Component} from 'react';
import {
    AppBar, 
    Toolbar 
} from '@material-ui/core';


class Header extends Component {
    render() {
        return (
            <AppBar position="absolute" color="default" className="odessa-nav-bar" >
                    <Toolbar>
                        <img src={require('../../assets/odessa_logo.png')} className="odessa-logo" alt="odessa logo" />
                    </Toolbar>
            </AppBar>
        );
    }
}

export default Header;