export default class MyReferencesPresenter {
    constructor(token, benefitsService, updateState) {
        this.state = {
            isLoading: false,
            token,
            references: []
        }

        this.benefitsService = benefitsService;
        this.updateState = updateState;
    }

    fetchReferences = async () => {
        this.startLoading();
        let references = await this.benefitsService.listReferences(this.state.token);
        this.state.references = references;
        this.updateState(this.state);
        this.stopLoading();
    }

    startLoading = () => {
        this.state.isLoading = true;
        this.updateState(this.state);
    }

    stopLoading = () => {
        this.state.isLoading = false;
        this.updateState(this.state);
    }

    showRefFriend = () => {
        window.location.href = `/refFriendFromList/1/${this.state.token}`;
    }
}