import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import Select from 'react-select';
import Header from '../shared/Header';
import BeneficiaryDataFormComponent from './BeneficiaryDataFormComponent';
import AddressForm from './AddressForm';
import '../shared/styles.css';
import BeneficiaryPresenterFactory from './BeneficiaryPresenterFactory';

class BeneficiaryComponent extends Component {

    constructor( props ) {
        super( props );
        const token = props.match.params.token;
        const companyId = props.match.params.companyId;
        this.presenter = BeneficiaryPresenterFactory.make(token, companyId, this.updateState);
        this.state = this.presenter.state;
    }

    componentDidMount() {
        this.presenter.fetchBeneficiaryList();
    }

    updateState = (newState) => {
        this.setState(newState);
    }

    render() {
        return ( 
            <React.Fragment>
                <Header />
                <Paper className="layout">
                        <Typography  gutterBottom >
                            Seleccione el beneficiario
                        </Typography>

                        <Select
                            value={this.state.beneficiarySelected}
                            onChange={this.presenter.onBeneficiarySelected}
                            options={this.state.beneficiaryList} />
                        { this.state.formErrors.beneficiary !== "" ? <FormHelperText error>{this.state.formErrors.beneficiary}</FormHelperText> :  "" }
                        
                        {
                            this.state.showForm ?
                            <div>
                                <BeneficiaryDataFormComponent 
                                    beneficiary={this.state.beneficiarySelected} 
                                    onDataChange={this.presenter.onDataChange}
                                    formErrors={this.state.formErrors} />
                                <AddressForm
                                    onDataChange={this.presenter.onDataChange}
                                    formErrors={this.state.formErrors} 
                                    token={this.state.token} />
                            </div>
                            :
                            <div />
                        }

                        <div className="new-section" />
                        <Grid container spacing={3}  >
                            <Grid item xs={6}>
                                <Button
                                    variant="contained" 
                                    color="secondary"
                                    onClick={this.presenter.onBackClicked} >
                                    Regresar
                                </Button>
                            </Grid>
                            <Grid item xs={6} >
                                <Button
                                    variant="contained" 
                                    color="primary"
                                    className="right-aligned"
                                    onClick={this.presenter.save} >
                                    Continuar
                                </Button>
                            </Grid>
                        </Grid>
                </Paper>
            </React.Fragment>
         );
    } 
}

export default BeneficiaryComponent;