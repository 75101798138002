import UserRepository from '../models/UserRepository';
import PersonaDTO from './PersonaDTO';
import {ODESSA_URL} from '../../shared/Constants';

export default class UserRepositoryImpl implements UserRepository {

    GET_DATA_ENDPOINT = "UpdateUser/ObtenerDatos";

    getUserData = async (token: string): Promise<PersonaDTO> => {
        const headers = new Headers({
            token
        })

        var options = { 
            method: 'POST',
            headers
        };

        const response = await (await fetch(`${ODESSA_URL}/${this.GET_DATA_ENDPOINT}`, options)).json();
        return response.Persona;
    }
}