export default class RefFriendSavedPresenter {

    constructor(token, fromList) {
        this.state = {
            fromList,
            token
        };
    }

    onBackClicked = () => {
        if( this.state.fromList === undefined && window.android ) {
            window.android.back();
        } else if( this.state.fromList === undefined && window.webkit ) {
            window.webkit.messageHandlers.refFriend.postMessage( "back" );
        } else {
            window.location.href = `/listReferences/${this.state.token}`;
        }
    }
}