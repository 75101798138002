import React, { Component } from 'react';
import { 
    Button, 
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    LinearProgress,
    FormHelperText,
    Paper,
    Typography, 
    Snackbar,
    IconButton,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Header from '../shared/Header';
import UpdatePolicyPresenterFactory from './UpdatePolicyPresenterFactory';
import PropTypes from 'prop-types';

class UpdatePolicyComponent extends Component {

    constructor(props) {
        super(props);
        const token = props.match.params.token;
        const productType = props.match.params.productType;
        const policyId = props.match.params.policyId;
        const policyNumber = props.match.params.policyNumber;

        let policyData = {
            token,
            productType,
            policyId,
            policyNumber
        };

        this.presenter = UpdatePolicyPresenterFactory.make(
            policyData,
            this.updateState
        );
        this.state = this.presenter.state;
    }

    updateState = (newState) => {
        this.setState(newState);
    }

    componentDidMount() {
        this.presenter.getUserData();
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                { this.state?.isLoading ?
                <LinearProgress />
                :""
                }
                <Paper className="layout">
                    <Typography  variant="h6" >
                        Corregir datos póliza
                    </Typography>

                    <div className="new-section" />
                    <Grid container spacing={3}  >
                        <Grid item xs={12}>
                            <span className="detail-title">Póliza:</span> <span className="detail-value">{this.state?.policy} - {this.state?.productTypeLabel}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="detail-title">Email:</span> <span className="detail-value">{this.state?.user?.email}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="detail-title">Teléfono:</span> <span className="detail-value">{this.state?.user?.phone}</span>
                        </Grid>
                    </Grid>

                    <div className="new-section" />
                    <Grid container spacing={3}  >
                        <Grid item xs={12}>
                            Selecciona los puntos que se desean corregir:
                            <Snackbar
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                                autoHideDuration={6000}
                                onClose={this.presenter.onCloseSnackbar}
                                open={this.state?.aggregatedError !== ""}
                                message={
                                    <span id="client-snackbar">
                                    {this.state?.aggregatedError}
                                    </span>
                                }
                                action={[
                                    <IconButton key="close" aria-label="close" color="inherit" onClick={this.presenter.onCloseSnackbar} >
                                      <CloseIcon />
                                    </IconButton>,
                                ]} />
                        </Grid>
                        <Grid item xs={12}>
                            
                        </Grid>

                        { this.state.selectedForm.map(question => (
                            <React.Fragment key={question.index}>
                            {question.checked}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="vehicleTypeCheck"
                                            name="vehicleTypeCheck"
                                            color="primary"
                                            onChange={(event) => this.presenter.onQuestionChecked(question.index)}
                                            value="primary"
                                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                                    }
                                    label={question.label} />
                            </Grid>
                            { question.checked ? 
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        label=""
                                        onChange={(event) => this.presenter.onQuestionChange(event, question.index)}
                                        variant="outlined"
                                        value={question.value}
                                        fullWidth />
                                    { question.error ? <FormHelperText error>{question.error}</FormHelperText> :  "" }
                                </Grid>
                            :  "" }
                            </React.Fragment>
                        )) }

                        <Grid item xs={12}>
                        <span className="detail-title">
                        Verificaremos tu solicitud y la gestionaremos con la aseguradora. 
                        En un lapso de 5 días hábiles actualizaremos tu póliza en tu portal y te la enviaremos vía correo electrónico.
                        </span>
                        </Grid>
                        
                        <Grid container spacing={3} className="button-container"  >
                            <Grid item xs={6} >
                                <Button
                                    variant="contained" 
                                    color="primary"
                                    className="full-width right-aligned"
                                    onClick={this.presenter.save}
                                    disabled={this.state?.isLoading} >
                                    Solicitar corrección
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button
                                    className="full-width"
                                    variant="contained" 
                                    color="primary"
                                    onClick={this.presenter.onBackClicked}
                                    disabled={this.state?.isLoading} >
                                    Regresar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                        
                </Paper>

                <Dialog
                    open={this.state.showDialog}
                    onClose={this.presenter.onDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">{"Gracias, hemos enviado tu solicitud."}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Una vez corregida tu póliza, podrás verla acutalizada en la sección Mis Seguros.
                        Adicional te la enviaremos por correo electrónico. 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.presenter.onDialogClose} color="primary">
                        Continuar
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

UpdatePolicyComponent.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired,
            productType: PropTypes.string.isRequired,
            policyId: PropTypes.string.isRequired,
            policyNumber: PropTypes.string.isRequired
        })
    })
};

export default UpdatePolicyComponent;