import React, { Component } from 'react';
import { 
    LinearProgress,
    Paper,
    Typography,
    Grid,
    Button,
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Header from '../shared/Header';
import MyReferencesPresenterFactory from './MyReferencesPresenterFactory';

const RefFriendButton = withStyles(theme => ({
    root: {
      color: "#fff",
      backgroundColor: yellow[800],
      '&:hover': {
        backgroundColor: yellow[900],
      },
    },
}))(Button);


class MyReferencesComponent extends Component {

    constructor(props) {
        super(props);
        const token = props.match.params.token;
        this.presenter = MyReferencesPresenterFactory.make(token, this.updateState);
        this.state = this.presenter.state;
    }

    componentDidMount() {
        this.presenter.fetchReferences();
    }

    updateState = (newState) => {
        this.setState(newState);
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                { this.state?.isLoading ?
                <LinearProgress />
                :""
                }
                <Paper className="layout">
                    <Typography  variant="h6" >
                        <img alt="references" src="https://referenciados.odessaseguros.com/images/menu/ref.png" />Tus Referenciados
                    </Typography>

                    <RefFriendButton variant="contained" onClick={this.presenter.showRefFriend}>Referenciar a un amigo</RefFriendButton>
                </Paper>

                


                {this.state.references?.map( (reference, i) => {
                    return (<Paper className="layout" key={i}>
                        <Grid container spacing={3} >
                            <Grid item xs={12} className="centered-content">
                                <img alt="userImage" src="https://referenciados.odessaseguros.com/images/ref/stanby.png" />
                                <Typography variant="h6">{reference.name}</Typography>
                                <Typography>{reference.status}</Typography>
                                <Typography>Prolizas vigentes: {reference.policies}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>);
                })}
                <div className="new-section"></div>
                
            </React.Fragment>
        );
    }
}

MyReferencesComponent.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired
        })
    })
};

export default MyReferencesComponent;