import React, { Component } from 'react';
import { 
    Grid,
    LinearProgress,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    FormHelperText
} from '@material-ui/core';
import Select from 'react-select';
import Header from '../shared/Header';
import PropTypes from 'prop-types';
import AddBeneficiaryPresentFactory from './AddBeneficiaryPresenterFactory';

class AddBeneficiaryComponent extends Component {

    constructor(props) {
        super(props);
        const token = props.match.params.token;
        const policyId = props.match.params.policyId;
        const policyNumber = props.match.params.policyNumber;
        this.presenter = AddBeneficiaryPresentFactory.make(token, policyNumber, policyId, this.updateState);
        this.state = this.presenter.state;
    }

    updateState = (newState) => {
        this.setState(newState);
    }

    componentDidMount() {
        this.presenter.fetchBeneficiaryList();
        this.presenter.getUserData();
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                { this.state?.isLoading ?
                <LinearProgress />
                :""
                }
                <Paper className="layout">
                    <Typography  variant="h6" >
                        Corregir datos póliza
                    </Typography>

                    <div className="new-section" />
                    <Grid container spacing={3}  >
                        <Grid item xs={12}>
                            <span className="detail-title">Póliza:</span> <span className="detail-value">{this.state?.policyNumber}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="detail-title">Email:</span> <span className="detail-value">{this.state?.user?.email}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="detail-title">Teléfono:</span> <span className="detail-value">{this.state?.user?.phone}</span>
                        </Grid>
                    </Grid>

                    <div className="new-section" />

                    <Typography  gutterBottom >
                        Podemos incluir un Beneficiario Preferente a tu póliza, solo es necesario que lo selecciones de la siguiente lista:
                    </Typography>

                    <Select
                        value={this.state.beneficiarySelected}
                        onChange={this.presenter.onBeneficiarySelected}
                        options={this.state.beneficiaryList} />
                    { this.state.aggregatedError ? <FormHelperText error>{this.state.aggregatedError}</FormHelperText> :  "" }

                    <div className="new-section" />

                    <Typography  gutterBottom >
                        <span className="detail-title">
                            Verificaremos tu solicitud y la gestionaremos con la aseguradora. En un lapso de 5 días hábiles la enviaremos vía correo electrónico.
                        </span>
                    </Typography>

                    <div className="new-section" > </div>
                    <Grid container spacing={3} className="button-container"  >
                        <Grid item xs={6} >
                            <Button
                                variant="contained" 
                                color="primary"
                                className="full-width right-aligned"
                                onClick={this.presenter.save}
                                disabled={this.state?.isLoading} >
                                Solicitar beneficiario
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                className="full-width"
                                variant="contained" 
                                color="primary"
                                onClick={this.presenter.onBackClicked}
                                disabled={this.state?.isLoading} >
                                Regresar
                            </Button>
                        </Grid>
                    </Grid>

                </Paper>

                <Dialog
                    open={this.state.showDialog}
                    onClose={this.presenter.onDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">{"Gracias, hemos enviado tu solicitud."}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Una vez que tengamos tu endoso de Beneficiario Preferente te lo enviaremos a tu correo electrónico. 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.presenter.onDialogClose} color="primary">
                        Continuar
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

AddBeneficiaryComponent.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired,
            policyId: PropTypes.string.isRequired,
            policyNumber: PropTypes.string.isRequired
        })
    })
};

export default AddBeneficiaryComponent;