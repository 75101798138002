import Util from '../../shared/Util';

const ATLAS_ID = "12";
const QUALITAS_ID = "6";
const AXA_ID = "10";
const ZURICH_ID = "15";

export default class BeneficiaryPresenter {

    companiesToValidate = [
        ATLAS_ID,
        QUALITAS_ID,
        AXA_ID,
        ZURICH_ID
    ];
    
    constructor(
        token,
        companyId,
        beneficiaryService,
        updateState
        ) {
        this.state = {
            beneficiaryList: [],
            beneficiarySelected: null,
            rfc: "",
            telefono: "",
            email: "",
            pais: "",
            calle: "",
            noInt: "",
            noExt: "",
            calles: "",
            cp: "",
            estado: "",
            municipio: "",
            colonia: "",
            formErrors: this.getEmptyFormErrors(),
            token,
            showForm: false,
            companyId
        };

        this.updateState = updateState;
        this.beneficiaryService = beneficiaryService;
    }

    getEmptyFormErrors = () => {
        var emptyFormErrors = {
            beneficiary: "",
            rfc: "",
            telefono: "",
            email: "",
            pais: "",
            cp: "",
            estado: "",
            municipio: "",
            colonia: ""
        };
        return emptyFormErrors;
    }

    fetchBeneficiaryList = async () => {
        let beneficiaries = await this.beneficiaryService.listBeneficiaries(this.state.token);
        this.state.beneficiaryList = beneficiaries.map( beneficiary => {
            return {
                value: beneficiary.id,
                label: beneficiary.name
            };
        } );
        this.state.beneficiarySelected = null;
        this.updateState(this.state);
    }

    onBeneficiarySelected = (beneficiarySelected) => {
        this.state.beneficiarySelected = beneficiarySelected;
        if (this.companiesToValidate.includes(this.state.companyId)) {
            this.checkCompleteData(this.state.beneficiarySelected)
        } else {
            this.state.showForm = true;
            this.updateState(this.state);
        }
    }

    checkCompleteData = async (beneficiarySelected) => {
        let completeData = await this.beneficiaryService.checkCompleteData(this.state.token, beneficiarySelected.label);
        if (!completeData) {
            this.state.showForm = true;
        }
        this.updateState(this.state);
    }

    onBackClicked = () => {
        if( window.android ) {
            window.android.back();
        } else if( window.webkit ) {
            window.webkit.messageHandlers.saveBeneficiary.postMessage( "back" );
        } else {
            console.log( "Back button pressed" );
        }
    }

    save = ( event ) => {

        if ( !this.validateForm() ) {
            window.scrollTo(0, 0);
            return;
        }
        
        const response = {
            beneficiario: this.state.beneficiarySelected.value,
            beneficiarioLabel: this.state.beneficiarySelected.label,
            rfc: this.state.rfc,
            telefono: this.state.telefono,
            email: this.state.email,
            pais: this.state.pais.value,
            paisLabel: this.state.pais.label,
            cp: this.state.cp,
            estado: this.state.estado,
            estadoLabel: this.state.estadoLabel,
            municipio: this.state.municipio,
            municipioLabel: this.state.municipioLabel,
            colonia: this.state.colonia,
            coloniaLabel: this.state.coloniaLabel,
            calle: this.state.calle,
            noExt: this.state.noExt,
            noInt: this.state.noInt,
            calles: this.state.calles
        };

        if( window.android ) {
            window.android.save( JSON.stringify( response ) );
        } else if( window.webkit ) {
            window.webkit.messageHandlers.saveBeneficiary.postMessage( JSON.stringify( response ) );
        } else {
            console.log( "save called with response: " );
            console.log( response );
        }
    }

    validateForm = () => {
        var isValidForm = true;
        this.state.formErrors = this.getEmptyFormErrors();
        this.updateState(this.state);

        if ( this.state.beneficiarySelected === null ) {
            this.state.formErrors.beneficiary = "Seleccione un beneficiario";
            this.updateState(this.state)
            isValidForm = false;
        }

        if (this.state.showForm) {
            isValidForm = this.validateSubForms(isValidForm);
        }
        return isValidForm;
    }

    validateSubForms = ( isValidForm ) => {
        
        if ( this.state.rfc === "" ) {
            this.state.formErrors.rfc = "Proporcione el rfc del beneficiario";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.rfc === "" || !Util.isRFC(this.state.rfc) ) {
            this.state.formErrors.rfc = "rfc no válido";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.telefono === "" ) {
            this.state.formErrors.telefono = "Proporcione el teléfono del beneficiario";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.email === "" ) {
            this.state.formErrors.email = "Proporcione el email del beneficiario";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.email === "" || !Util.isEmail(this.state.email) ) {
            this.state.formErrors.email = "email no válido";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.pais === "" ) {
            this.state.formErrors.pais = "Seleccione un país";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.calle === "" ) {
            this.state.formErrors.calle = "Proporcione la calle";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.noExt === "" ) {
            this.state.formErrors.noExt = "Proporcione el número exterior";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.cp === "" ) {
            this.state.formErrors.cp = "Proporcione un código postal válido";
            this.updateState(this.state);
            isValidForm = false;
        }
        
        if ( this.state.estado === "" ) {
            this.state.formErrors.estado = "Proporcione un estado";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.municipio === "" ) {
            this.state.formErrors.municipio = "Proporcione un municipio";
            this.updateState(this.state);
            isValidForm = false;
        }

        if ( this.state.colonia === "" ) {
            this.state.formErrors.colonia = "Proporcione una colonia";
            this.updateState(this.state);
            isValidForm = false;
        }

        return isValidForm;
    }


    onDataChange = ( data ) => {
        let key;
        for(key in data) {
            this.state[key] = data[key];
        }
        this.state.formErrors = this.getEmptyFormErrors();
        this.updateState(this.state);
    }
}