
import PolicyRepository from '../models/PolicyRepository';

export interface PolicyService {
    updatePolicy(token: string, request: any): Promise<boolean>;
}


export default class PolicyServiceImpl implements PolicyService {

    policyRepository: PolicyRepository;

    constructor(policyRepository: PolicyRepository) {
        this.policyRepository = policyRepository;
    }
    
    updatePolicy = async (token: string, request: any ): Promise<boolean> => {
        return this.policyRepository.updatePolicy(token, request);
    }
}