import BeneficiaryRepository from '../models/BeneficiaryRepository';
import BeneficiaryDTO from './BeneficiaryDTO';
import {ODESSA_URL} from '../../shared/Constants';

export default class BeneficiaryRepositoryImpl implements BeneficiaryRepository {

    LIST_ENDPOINT = "Issue/Beneficiaries";
    CHECK_COMPLETE_ENDPOINT = "Issue/Beneficiaries/DatosIncompletos";


    list = async (token: string): Promise<BeneficiaryDTO[]> => {
        const headers = new Headers({
            token
        })

        var options = { 
            method: 'GET',
            headers
        };

        const response = await (await fetch(`${ODESSA_URL}/${this.LIST_ENDPOINT}`, options)).json();
        return response;
    }

    checkCompleteData = async (token: string, beneficiaryName: string): Promise<boolean> => {
        const headers = new Headers({
            token
        })

        var options = { 
            method: 'POST',
            headers
        };

        const response =  await (await fetch(`${ODESSA_URL}/${this.CHECK_COMPLETE_ENDPOINT}/${beneficiaryName}`, options)).body;
        console.log(response);
        return true;
    }
}