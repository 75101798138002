import BeneficiaryPresenter from './BeneficiaryPresenter';
import BeneficiaryRepositoryImpl from '../../core/data/BeneficiaryRepositoryImpl';
import BeneficiaryServiceImpl from "../../core/usecases/BeneficiaryService";

export default class BeneficiaryPresentFactory {
    static make = (token, companyId, updateState) => {
        let beneficiaryRepository = new BeneficiaryRepositoryImpl();
        let beneficiaryService = new BeneficiaryServiceImpl(beneficiaryRepository);
        let presenter = new BeneficiaryPresenter(token, companyId, beneficiaryService, updateState);
        return presenter;
    }
}