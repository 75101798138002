import RefFriendFormPresenter from './RefFriendFormPresenter';
import BenefitsRepositoryImpl from '../../core/data/BenefitsRepositoryImpl';
import BenefitsServiceImpl from '../../core/usecases/BenefitsService';

export default class RefFriendFormPresenterFactory {
    static make(token, updateState, onSave, fromList) {
        let benefitsRepository = new BenefitsRepositoryImpl();
        let benefitsService = new BenefitsServiceImpl(benefitsRepository);
        let presenter = new RefFriendFormPresenter(token, benefitsService, updateState, onSave, fromList);
        return presenter;
    }
}