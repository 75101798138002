import React from 'react';
import './App.css';
import BeneficiaryComponent from './components/beneficiary/BeneficiaryComponent';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import RefFriendComponent from './components/refFriend/RefFriendComponent';
import UpdatePolicyComponent from './components/updatePolicyData/UpdatePolicyComponent';
import AddBeneficiaryComponent from './components/beneficiary/AddBeneficiaryComponent';
import RefBuyerComponent from './components/refBuyer/RefBuyerComponent';
import MyReferencesComponent from './components/myReferences/MyReferencesComponent';

const theme = createMuiTheme({
  palette: {
      primary: {
          light: '#FF8200',
          main: '#FF8200',
          dark: '#FF8200',
          contrastText: '#fff',
      },
      secondary: {
          light: '#626262',
          main: '#626262',
          dark: '#626262',
          contrastText: '#fff',
      },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Route path="/beneficiary/:companyId/:token" component={BeneficiaryComponent} />
        <Route path="/refFriendFromList/:fromList/:token" component={RefFriendComponent} />
        <Route path="/refFriend/:token" component={RefFriendComponent} />
        <Route path="/refBuyer/:token" component={RefBuyerComponent} />
        <Route path="/listReferences/:token" component={MyReferencesComponent} />
        <Route path="/updatePolicyData/:productType/:policyId/:policyNumber/:token" component={UpdatePolicyComponent} />
        <Route path="/addBeneficiary/:policyId/:policyNumber/:token" component={AddBeneficiaryComponent} />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
