import BeneficiaryRepository from '../models/BeneficiaryRepository';
import Beneficiary from '../models/Beneficiary';
import BeneficiaryDTO from '../data/BeneficiaryDTO';

export interface BeneficiaryService {
    listBeneficiaries(token: string): Promise<Beneficiary[]>; 
    checkCompleteData(token: string, companyName: string): Promise<Boolean>;
}

export default class BeneficiaryServiceImpl implements BeneficiaryService {

    beneficiaryRepository: BeneficiaryRepository;

    constructor(beneficiaryRepository: BeneficiaryRepository) {
        this.beneficiaryRepository = beneficiaryRepository;
    }
    
    listBeneficiaries = async (token: string): Promise<Beneficiary[]> => {
        return (await this.beneficiaryRepository.list(token)).map( dto => {
            return this.responseToBeneficiary(dto);
        } );
    } 

    checkCompleteData = async(token: string, companyName: string): Promise<Boolean> => {
        return await this.beneficiaryRepository.checkCompleteData(token, companyName);
    }

    responseToBeneficiary = (response: BeneficiaryDTO): Beneficiary => {
        return new Beneficiary(response.clave, response.beneficiario);
    }
}