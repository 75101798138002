import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Grid} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {FormHelperText} from '@material-ui/core';
import {Button} from '@material-ui/core';
import {CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import RefBuyerFormPresenterFactory from './RefBuyerFormPresenterFactory';

class RefBuyerFormComponent extends Component {

    constructor( props ) {
        super( props );

        let token = props.token;
        this.presenter = RefBuyerFormPresenterFactory.make(token, this.updateState, this.props.onSave);

        this.state = this.presenter.state;
    }

    updateState = (newState) => {
        this.setState(newState);
    }

    render() {
        return (
            <Paper className="layout" id="refFriendForm">
                <Typography  variant="h5" >
                    Referencía a tu Comprador
                </Typography>
                <div className="new-section" />

                <Typography paragraph={true}>
                    Comparte los beneficios de Odessa Seguros a la persona que compró tu auto y gana un premio. 
                </Typography>
                <Typography variant="subtitle1">
                    Proporciónanos su información: 
                </Typography>

                <div className="new-section" />
                <Grid container spacing={3}  >
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="name"
                            label="Nombre(s)"
                            onChange={this.presenter.onNameChange}
                            variant="outlined"
                            value={this.state.name}
                            fullWidth />
                        { this.state.formErrors.name !== "" ? <FormHelperText error>{this.state.formErrors.name}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            id="lastName"
                            label="Apellido Paterno"
                            onChange={this.presenter.onLastNameChange}
                            variant="outlined"
                            value={this.state.lastName}
                            fullWidth />
                        { this.state.formErrors.lastName !== "" ? <FormHelperText error>{this.state.formErrors.lastName}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            id="lastName"
                            label="Apellido Materno"
                            onChange={this.presenter.onSecondLastNameChange}
                            variant="outlined"
                            value={this.state.secondLastName}
                            fullWidth />
                        { this.state.formErrors.secondLastName !== "" ? <FormHelperText error>{this.state.formErrors.secondLastName}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            id="phone"
                            label="Teléfono"
                            type="phone"
                            onChange={this.presenter.onPhoneChange}
                            variant="outlined"
                            value={this.state.phone}
                            fullWidth />
                        { this.state.formErrors.phone !== "" ? <FormHelperText error>{this.state.formErrors.phone}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            id="email"
                            label="Correo"
                            type="email"
                            onChange={this.presenter.onEmailChange}
                            variant="outlined"
                            value={this.state.email}
                            fullWidth />
                        { this.state.formErrors.email !== "" ? <FormHelperText error>{this.state.formErrors.email}</FormHelperText> :  "" }
                    </Grid>

                    <Grid item xs={12}>
                    Por cada seguro de autos que contrate tu referenciado durante un año, 
                    ganarás un premio que consiste en 
                    <span className="odessa-text"> un vale de entrada al cine para 2 personas y un combo de palomitas y refresco.</span>
                    </Grid>

                    { this.state.isLoading ? 
                    <Grid item xs={12} className="centered-content">
                        <CircularProgress />
                    </Grid>
                    :
                    ""
                    }

                    <Grid item xs={6}>
                        <Button
                            variant="contained" 
                            color="secondary"
                            onClick={this.presenter.onBackClicked}
                            disabled={this.state.isLoading} >
                            Cancelar
                        </Button>
                    </Grid>
                    
                    <Grid item xs={6} >
                        
                        <Button
                            id="saveButton"
                            variant="contained" 
                            color="primary"
                            className="right-aligned"
                            onClick={this.presenter.save}
                            disabled={this.state.isLoading} >
                            Invitar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

RefBuyerFormComponent.propTypes = {
    onSave: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired
}

export default RefBuyerFormComponent;