import React, {Component} from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import Select from 'react-select';
import {Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {FormHelperText} from '@material-ui/core';
import {ODESSA_URL} from '../../shared/Constants';

const validateCPEndPoint = "Person/DatosCP";

class AddressWithCP extends Component {


    constructor( props ) {
        super( props );
        this.state = {
            cp: "",
            cpLabel: "",
            estado: "",
            estadoLabel: "",
            municipio: "",
            municipioLabel: "",
            colonia: null,
            colonias: [ ]
        };
    }


    onChangeCP = ( event ) => {
        this.setState({
            cpLabel: event.target.value
        });

        this.validaCP( event.target.value );
    }


    onValidaCPClick = ( ) => {

        this.validaCP( this.state.cpLabel );

    }

    
    validaCP = ( cp ) => {

        var url = `${ODESSA_URL}/${validateCPEndPoint}/${cp}`;

        var validateCPHeaders = new Headers({
            "token": this.props.token
        });

        var validateCPOptions = { 
            method: 'GET',
            headers: validateCPHeaders,
            mode: 'cors',
            cache: 'default' 
        };

        fetch( url, validateCPOptions )
        .then( response => {
            return response.json()
         } ).then( cpData => {


            var newState = {};

            if( cpData.PaisID !== -1 ) {

                var colonies = cpData.Colonias.map( colony => ({

                    value: colony.id,
                    label: colony.name

                }));

                newState = {
                    cp: this.state.cpLabel,
                    estado: cpData.EstadoID,
                    estadoLabel: cpData.Estado,
                    municipio: cpData.PoblacionID,
                    municipioLabel: cpData.Poblacion,
                    colonias: colonies,
                    colonia: ""
                }


                this.setState( newState );

            } else {


                newState = {
                    cp: "",
                    estado: "",
                    estadoLabel: "",
                    municipio: "",
                    municipioLabel: "",
                    colonias: [],
                    colonia: "",
                    coloniaLabel: ""
                };

                this.setState( newState );
            }

            this.props.onDataChange( newState );
            
         } );

    }

    onColoniaSelected = ( selectedOption ) => {
        this.setState( {
            colonia: selectedOption
        } );

        this.props.onDataChange( {colonia: selectedOption.value, coloniaLabel: selectedOption.label} ); 

    }


    render ( ) {
        return(
            <div className="new-section">
                <Grid container spacing={3} >
                    <Grid item xs={7}>
                        <TextField 
                            value={this.state.cpLabel}
                            name="cp"
                            id="cp"
                            fullWidth
                            label="Código Postal"
                            type="number"
                            variant="outlined"
                            onChange={this.onChangeCP} />
                        { this.props.formErrors.cp !== "" ? <FormHelperText error>{this.props.formErrors.cp}</FormHelperText> :  "" }
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={this.onValidaCPClick} >Validar</Button>
                    </Grid>

                    { this.state.estado !== "" ?
                        <Grid item xs={12}>
                            Estado: {this.state.estadoLabel}
                        </Grid>
                        :
                        <div></div>
                    }

                    { this.state.town !== "" ?
                        <Grid item xs={12}>
                            Municipio: {this.state.municipioLabel}
                        </Grid>
                        :
                        <div></div>
                    }

                    <Grid item xs={12} >
                        <Typography  gutterBottom >
                            Colonia
                        </Typography>
                    
                        <Select
                            required
                            name="colony"
                            fullWidth
                            value={this.state.colonia}
                            onChange={this.onColoniaSelected}
                            options={this.state.colonias} />

                        { this.props.formErrors.colonia !== "" ? <FormHelperText error>{this.props.formErrors.colonia}</FormHelperText> :  "" }

                    </Grid>

                </Grid>
                
            </div>
        );
    }
}


AddressWithCP.propTypes = {
    onDataChange: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired
}


export default AddressWithCP;
