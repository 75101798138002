import React, {Component} from 'react';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import BeneficiaryDataFormPresenterFactory from './BeneficiaryDataFormPresenterFactory';

class BeneficiaryDataFormComponent extends Component {

    constructor( props ) {
        super(props); 
        this.presenter = BeneficiaryDataFormPresenterFactory.make(this.props.onDataChange, this.updateState);
        this.state = this.presenter.state;
    }

    updateState = (newState) => {
        this.setState(newState);
    }
    
    render( ) {
        return (
            <div className="new-section">
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <TextField
                            required
                            id="rfc"
                            label="RFC"
                            onChange={this.presenter.onRfcChange}
                            variant="outlined"
                            value={this.state.rfc}
                            fullWidth />
                        { this.props.formErrors.rfc !== "" ? <FormHelperText error>{this.props.formErrors.rfc}</FormHelperText> :  "" }
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            required
                            id="razonSocial"
                            label="Razón Social"
                            readOnly="readOnly"
                            value={this.props.beneficiary.label}
                            variant="outlined"
                            fullWidth />
                        
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            id="telefono"
                            label="Teléfono"
                            type="tel"
                            onChange={this.presenter.onTelefonoChange}
                            variant="outlined"
                            fullWidth  />
                        { this.props.formErrors.telefono !== "" ? <FormHelperText error>{this.props.formErrors.telefono}</FormHelperText> :  "" }

                    </Grid>

                    <Grid item xs={12}>

                        <TextField
                            id="email"
                            label="Email"
                            type="email"
                            onChange={this.presenter.onEmailChange}
                            variant="outlined"
                            fullWidth />
                        { this.props.formErrors.email !== "" ? <FormHelperText error>{this.props.formErrors.email}</FormHelperText> :  "" }
                    </Grid>
                </Grid>
            </div>
        );
        
    }

}

BeneficiaryDataFormComponent.propTypes = {
    beneficiary: PropTypes.object.isRequired,
    onDataChange: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired
}

export default BeneficiaryDataFormComponent;