export const formQuestions = {
    vehicle: [
        {
            index: 0,
            checked: false,
            label: "El vehículo dice Standard y es Automático o viceversa.",
            value: "",
            error: ""
        },
        {
            index: 1,
            checked: false,
            label: "Número de serie o placas.",
            value: "",
            error: ""
        },
        {
            index: 2,
            checked: false,
            label: "Dirección, RFC, Nombre del Asegurado.",
            value: "",
            error: ""
        },
        {
            index: 3,
            checked: false,
            label: "Agregar conductor habitual.",
            value: "",
            error: ""
        }
    ],

    life_funeral: [
        {
            index: 0,
            checked: false,
            label: "Datos de los beneficiarios.",
            value: "",
            error: ""
        },
        {
            index: 1,
            checked: false,
            label: "Dirección, RFC del Asegurado.",
            value: "",
            error: ""
        }
    ]
};