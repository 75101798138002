export default class BeneficiaryDataFormPresenter {
    constructor(updateState, onDataChange) {
        this.state = {
            rfc: "",
            telefono: "",
            email: "",
        }
        this.updateState = updateState;
        this.onDataChange = onDataChange;
    }

    onRfcChange = ( event ) => {
        this.state.rfc =  event.target.value.toUpperCase();
        this.updateState(this.state);
        this.onDataChange(this.state);
    }

    onTelefonoChange = ( event ) => {
        this.state.telefono = event.target.value;
        this.updateState(this.state);
        this.onDataChange(this.state);
    }

    onEmailChange = ( event ) => {
        this.state.email = event.target.value;
        this.updateState(this.state);
        this.onDataChange(this.state);
    }
}